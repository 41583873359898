import React from "react"
import "./src/styles/global.css"
import { UserContextProvider } from "./src/components/context/UserContext"
import { ModalContextProvider } from "./src/components/context/ModalContext"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

const queryClient = new QueryClient()

export const wrapRootElement = ({ element }) => (
    <QueryClientProvider client={queryClient}>
        <UserContextProvider>
            <ModalContextProvider>{element}</ModalContextProvider>
        </UserContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
)
