// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-common-product-common-product-tsx": () => import("./../../../src/components/templates/common-product/CommonProduct.tsx" /* webpackChunkName: "component---src-components-templates-common-product-common-product-tsx" */),
  "component---src-components-templates-gadget-gadget-tsx": () => import("./../../../src/components/templates/gadget/Gadget.tsx" /* webpackChunkName: "component---src-components-templates-gadget-gadget-tsx" */),
  "component---src-components-templates-page-page-tsx": () => import("./../../../src/components/templates/page/Page.tsx" /* webpackChunkName: "component---src-components-templates-page-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

