import { isBrowser } from "./isBrowser"

export const getLocalStorage = (key: string) => {
    if (isBrowser()) {
        const localStorageValue = window.localStorage.getItem(key)
        if (localStorageValue) {
            return JSON.parse(localStorageValue)
        }
    }
}

export const setLocalStorage = (key: string, value: any) => {
    if (isBrowser()) {
        window.localStorage.setItem(key, value)
    }
}

export const removeLocalStorage = (key: string) => {
    if (isBrowser()) {
        window.localStorage.removeItem(key)
    }
}
