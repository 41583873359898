import React, { useState, useEffect, createContext } from "react"
import { getLocalStorage } from "../../utils/localStorage"

export const UserContext = createContext({})

export const UserContextProvider = ({ children }: props) => {
    const [userData, setUserData] = useState()
    const [userOrder, setUserOrder] = useState()

    useEffect(() => {
        if (getLocalStorage("user")) {
            setUserData(getLocalStorage("user"))
        }
        if (getLocalStorage("orders")) {
            setUserOrder(getLocalStorage("orders"))
        }
    }, [])
    return (
        <UserContext.Provider
            value={{
                userData,
                userOrder,
                setUserData,
                setUserOrder,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

interface props {
    children: React.ReactNode
}
