import React, { useState, createContext } from "react"

export const ModalContext = createContext({})

export const ModalContextProvider = ({ children }: props) => {
    const [modalActive, setModalActive] = useState(false)
    const [navigateAfterClosing, setNavigateAfterClosing] = useState(false)

    return (
        <ModalContext.Provider
            value={{
                navigateAfterClosing,
                setNavigateAfterClosing,
                modalActive,
                setModalActive,
            }}
        >
            {children}
        </ModalContext.Provider>
    )
}

interface props {
    children: React.ReactNode
}
